import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        <a href="https://www.linkedin.com/company/s5-alert/?viewAsMember=true" target="_blank">
        <LinkedInIcon />
        </a>
      </div>
      <p> &copy; 2022-27 s5alert.com</p>
    </div>
  );
}

export default Footer;