import React, { Fragment } from "react";
import {useState, useRef} from 'react';
import { Link } from "react-router-dom";
import BannerImage from "./assets/school-banner.jpg";
import workFlowImage from "./assets/workFlow.png";
import surveillanceImage from "./assets/Surveillance.png";
import detectionImage01 from "./assets/Detection1.png";
import detectionImage02 from "./assets/Detection2.png";
import AlertImage from "./assets/Alert2.png";
import TampaBayNewsVideo from "./assets/TampaBay-News.mp4";
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ControlIcons from './components/ControlIcons';
// import 'react-videoplayer/lib/index.css';

import "./styles/Home.css";

const format = (seconds) => {
  if (isNaN(seconds)) {
    return '00:00'
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
  } else {
      return `${mm}:${ss}`
  }
};

function Home() {
  const [playerstate, setPlayerState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    playerbackRate:1.0,
    played:0,
    seeking: false,
  })

  //Destructure State in other to get the values in it
  const { playing, muted, volume, playerbackRate, played, seeking } = playerstate;
  const playerRef = useRef(null);
  const playerDivRef = useRef(null);

  //This function handles play and pause onchange button
  const handlePlayAndPause = () => {
    setPlayerState({...playerstate, playing: !playerstate.playing})
  }

  const handleMuting = () => {
    setPlayerState({...playerstate, muted: !playerstate.muted})
  }

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
  }

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30)
  }

  const handleVolumeChange = (e, newValue) => {
    setPlayerState({...playerstate, volume:parseFloat(newValue/100), muted:newValue === 0 ? true : false, });
  }

  const handleVolumeSeek = (e, newValue) => {
    setPlayerState({...playerstate, volume:parseFloat(newValue/100), muted:newValue === 0 ? true : false, });
  }

  const handlePlayerRate = (rate) => {
    setPlayerState({...playerstate, playerbackRate: rate});
  }

  const handleFullScreenMode = () => {
    screenfull.toggle(playerDivRef.current)
  }

  const handlePlayerProgress = (state) => {
    console.log('onProgress', state);
    if (!playerstate.seeking) {
      setPlayerState({...playerstate, ...state});
    }
    console.log('afterProgress', state);
  }

  const handlePlayerSeek = (e, newValue) => {
    setPlayerState({...playerstate, played: parseFloat(newValue / 100)});
    playerRef.current.seekTo(parseFloat(newValue / 100));
    // console.log(played)
  }

  const handlePlayerMouseSeekDown = (e) => {
    setPlayerState({...playerstate, seeking: true});
  }

  const handlePlayerMouseSeekUp = (e, newValue) => {
    setPlayerState({...playerstate, seeking: false});
    playerRef.current.seekTo(newValue / 100);
  }

  const currentPlayerTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
  const movieDuration =  playerRef.current ? playerRef.current.getDuration() : '00:00';
  const playedTime = format(currentPlayerTime);
  const fullMovieTime = format(movieDuration);

  return (
    <Fragment>
    <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
      <div className="headerContainer">
        <div style={{ marginTop: 90, marginBottom: 50 }}>
        <h1> S5 Alert </h1>
        </div>
        <div style={{ marginTop: 50, marginBottom: 50 }}>
        <p> SMART SURVEILLANCE SYSTEM FOR SCHOOL SECURITY </p>
        {/* <hr  style={{ color: '#000000', backgroundColor: '#000000', height: 2, width: '97%', borderColor : '#000000' }} /> */}
        </div>
        <div style={{ marginTop: 25, marginBottom: 25 }}>
        <Link to="/Login">
          <button> LOGIN </button>
        </Link>
        </div>
        <div style={{ marginTop: 25, marginBottom: 25 }}>
        <hr  style={{ color: '#000000', backgroundColor: '#000000', height: 2, width: '97%', borderColor : '#000000' }} />
        <img src={workFlowImage} alt="Work Flow Diagram" style={{ width: "97%" }}/>
        </div>
        <div style={{ marginTop: 25, marginBottom: 50 }}>
        <h2 className="text-center">The S5-Alert mission is to make schools a safer place for kids by enhancing existing video surveillance</h2>
        </div>
      </div>
    </div>

    {/* <div className="container position-relative"> */}
    <div style={{ marginTop: 150, marginBottom: 50 }}>
      <div className="product-title">
        <h3>S5-Alert brings in artificial intelligence powered enhanced surveillance and real-time notification service to boost school safety</h3>          
      </div>
      <Container>
      <div className='playerDiv' ref={playerDivRef}>
          <ReactPlayer width={'100%'} height={'100%'}
          ref={playerRef} 
          url={TampaBayNewsVideo}
          playing={playing}
          volume={volume} 
          playbackRate={playerbackRate}
          onProgress={handlePlayerProgress}
          muted={muted}/>

          <ControlIcons
           key={volume.toString()}
           playandpause={handlePlayAndPause}
           playing={playing}
           //rewind={handleRewind}
           //fastForward={handleFastForward}
           muting={handleMuting}
           muted={muted}
           volumeChange={handleVolumeChange}
           volumeSeek={handleVolumeSeek}
           volume={volume}
           playerbackRate={playerbackRate}
           playRate={handlePlayerRate}
           fullScreenMode={handleFullScreenMode}
           played={played}
           onSeek={handlePlayerSeek}  
           onSeekMouseUp={handlePlayerMouseSeekUp}
           onSeekMouseDown={handlePlayerMouseSeekDown}
           playedTime={playedTime}
           fullMovieTime={fullMovieTime}
           seeking={seeking}
          />
        {/* <ReactPlayer url='https://www.baynews9.com/fl/tampa/news/2023/02/28/local-entrepreneur-develops-ai-software-with-a-focus-on-school-safety#' /> */}
        {/* https://www.baynews9.com/fl/tampa/news/2023/02/28/local-entrepreneur-develops-ai-software-with-a-focus-on-school-safety# */}
        {/* <video ref="vidRef" src={TampaBayNewsVideo} type="video/mp4"></video> */}
      </div>
      <p>Courtesy of Cait McVey, Spectrum Bay News</p>      
      </Container>

      <div className="row equalize sm-equalize-auto">
          <div className="col-md-4 text-left sm-offset-30px-bottom">
            <div className="position-relative inset-45px-all sm-padding-30px-all height-100 width-100 border-all border-width-2 border-color-extra-light-gray display-table bg-light-gray border-radius-15">
              <div className="display-table-cell vertical-align-top"><span className="text-medium text-medium text-font-sec offset-10px-bottom display-block text-extra-dark-gray">24/7 Capable Monitoring</span>
                <span className="text-font-sec text-medium-gray text-medium">Integrate software into existing camera live feeds for round-the-clock surveillance.</span>
                <hr  style={{ color: '#000000', backgroundColor: '#000000', height: 0.5, width: '97%', borderColor : '#000000' }} />
                <img src={surveillanceImage} alt="Surveillance" style={{ width: "97%" }}/>
              </div>
            </div>
          </div>
          
          <div className="col-md-4 text-left sm-offset-30px-bottom">
            <div className="position-relative inset-45px-all sm-padding-30px-all height-100 width-100 border-all border-width-2 border-color-extra-light-gray display-table bg-light-gray border-radius-15">
              <div className="display-table-cell vertical-align-top"><span className="text-medium text-medium text-font-sec offset-10px-bottom display-block text-extra-dark-gray">AI Powered Threat Detection</span>
                <span className="text-font-sec text-medium-gray text-medium">Recognizes potential weapon threats nearing, entering, and inside of schools.</span>
                <hr  style={{ color: '#000000', backgroundColor: '#000000', height: 0.5, width: '97%', borderColor : '#000000' }} />
                <img src={detectionImage01} alt="Visible-range Detection" style={{ width: "97%" }}/>
                <br></br>
                <br></br>
                <img src={detectionImage02} alt="Visible-range Detection" style={{ width: "97%" }}/>
              </div>
            </div>
          </div>
          
          <div className="col-md-4 text-left sm-offset-30px-bottom">
            <div className="position-relative inset-45px-all sm-padding-30px-all height-100 width-100 border-all border-width-2 border-color-extra-light-gray display-table bg-light-gray border-radius-15">
              <div className="display-table-cell vertical-align-top"><span className="text-medium text-medium text-font-sec offset-10px-bottom display-block text-extra-dark-gray">Rapid Alerting</span>
                <span className="text-font-sec text-medium-gray text-medium">A text alert is sent with key data including which camera location returned a detection and an image of the weapon and person of interest.</span>
                <hr  style={{ color: '#000000', backgroundColor: '#000000', height: 0.5, width: '97%', borderColor : '#000000' }} />
                <img src={AlertImage} alt="Non-visible-range Detection" style={{ width: "97%" }}/>
              </div>
            </div>
          </div>
        
        </div>
      <hr></hr>
    </div>
    
    </Fragment>
  );
  }

export default Home;