import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';

import { useState, useEffect, useRef } from "react";
import { getUser, resetUserSession } from "./service/AuthService";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import GetAppIcon from '@mui/icons-material/GetApp';
import LogoutIcon from '@mui/icons-material/Logout';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Stack from '@mui/material/Stack';
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/Customer.scss";

const crudAssetsUrl = 'https://fd1k8w830l.execute-api.us-east-1.amazonaws.com/prod/item';
const fetchAllUrl = 'https://fd1k8w830l.execute-api.us-east-1.amazonaws.com/prod/items';
//const s3UploadUrl = 'https://3zb49gju58.execute-api.us-east-1.amazonaws.com/prod/s5alert-file-upload';
const s3UploadUrl = 'https://rjsydwsyvb.execute-api.us-east-1.amazonaws.com/prod/s5alert-fileupload';
// const s3UploadUrl = '/prod/s5alert-file-upload';
const initialFileState = { alt: "", src: "" };

//export default function EnhancedTable() {
const CustomerPortal = (props) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const user = getUser();
  const name = user !== 'undefined' && user ? user.name : '';
  const username = user !== 'undefined' && user ? user.username : '';
  const [{ alt, src }, setPreview] = useState(initialFileState);
  const [file, setFile] = useState(null);
  const [processedFile, setProcessedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [uploadTimeStamp, setUploadTimeStamp] = useState(null);
  const [resultList, setResultList] = useState(null);
  const [resultData, setResultData] = useState(null);

  const [rows, setRows] = useState([]);
  const [phoneArray, setPhoneArray] = useState([])//{ phoneid: uuidv4(), phoneNumber: '8179956906' },{ phoneid: uuidv4(), phoneNumber: '8177043239' }]);

  let newDate = new Date().toLocaleString() + '';

  const [aid, setAid] = useState(uuidv4());
  const [aname, setAname] = useState('');
  const [alocation, setAlocation] = useState('');
  const [adateadded, setAdateadded] = useState(newDate);
  const [aaccessstring, setAaccessstring] = useState('');
  //const [phoneid, setPhoneId] = useState(uuidv4());
  //const [phonenumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState(null);
  const [phonemessage, setPhoneMessage] = useState(null);
  const [settingsmessage, setSettingsMessage] = useState(null);
  const [runningmessage, setRunningMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneloading, setPhoneLoading] = useState(false);
  const [settingsloading, setSettingsLoading] = useState(false);
  const [EC2Stateloading, setEC2StateLoading] = useState(false);
  let EC2CheckIntervalID = useRef(null);
  let appRunCheckIntervalID = useRef(null);

  const [txmode, setTXMode] = React.useState('Software');
  const [runstarttime, setRunStartTime] = React.useState('');
  const [runendtime, setRunEndTime] = React.useState('');
  const [detectiondays, setDetectionDays] = React.useState('');
  const [detectionfrequency, setDetectionFrequecy] = React.useState('');
  const [computelocation, setComputeLocation] = React.useState('Cloud');
  const [computeState, setComputeState] = React.useState(null);
  const [computeStatus, setComputeStatus] = React.useState(null);
  const [appRunning, setAppRunning] = React.useState(null);
  const [stopAppTrigger, setStopAppTrigger] = React.useState(false);
  
  useEffect(() => {
    assetListRetrieval();
    phoneListRetrieval();
    configsRetrieval();
    EC2stateRetrieval();
    setMessage(null);
    setPhoneMessage(null);
    setSettingsMessage(null);
    setStopAppTrigger(false);
  }, []);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    {
      id: 'cameraid',
      numeric: false,
      disablePadding: true,
      label: 'Id',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'loc',
      numeric: true,
      disablePadding: false,
      label: 'Location',
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Date Added',
    },
    {
      id: 'accessstring',
      numeric: true,
      disablePadding: false,
      label: 'Access String',
    },
  ];
  
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all cameras',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Registered Assets
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={deleteAssetsHandler}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  let history = useNavigate();

  const logoutHandler = () => {
      resetUserSession();
      history('/');
  }

	function previewImage() {
		var file = document.getElementById("file").files
		if (file.length > 0) {
			var fileReader = new FileReader()

			fileReader.onload = function (event) {
				document.getElementById("preview").setAttribute("src", event.target.result)
			}

			fileReader.readAsDataURL(file[0])
		}
	}

  const onFileChange = (event) => {
      event.preventDefault();
      console.log("FILE SELECTED: ", event.target.files);
      const { files } = event.target;
      setPreview(
        files.length
          ? {
              src: URL.createObjectURL(files[0]),
              alt: files[0].name
            }
          : initialFileState
      );
      setFile(event.target.files[0]);
      // setProcessedFile(event.target.files[0]);
  };

  const resultRetrieval = () => {
      // const s3DownloadUrl = 'https://2crmsfcrk9.execute-api.us-east-1.amazonaws.com/v1/s5alert-results-out/?file=' + processedFile.name.replace(".", "_" + uploadTimeStamp + "_processed.");
      const s3DownloadUrl = 'https://2crmsfcrk9.execute-api.us-east-1.amazonaws.com/v1/s5alert-results-out/?file=' + 'none';
      axios({
          method: 'get',
          url: s3DownloadUrl,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
          }
      }).then (response => {
          console.log(response.data);
          setResultList(response.data.filelist);
          setResultData(response.data.filedata);
      }).catch (error => {
          console.log('Error occured', error);
      })
  }

  const onFileUpload = () => {
      // event.preventDefault();
      console.log(file.name)
      setErrorMessage(null);

      axios({
          method: 'post',
          url: s3UploadUrl,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
              "userName": username,
              "fileName": file.name,
              "fileType": file.type
          },
          data: file

      }).then(response => {
          setFile(null);
          setUploadStatus(true);
          setUploadTimeStamp(response.data.split(':')[1]);
          //console.log(response);

      }).catch(error => {
          if (error.response.status === 415) {
              setErrorMessage(error.response.data);
          } else{
              setErrorMessage('sorry... the backend server is down!! please try again later');
          }
      })
  }

  const fileData = () => {
      if (file) {
          return (
          //<div className="main">
          <>
              <p className="main">Selected file details:</p>
              <p className="info">File name: {file.name}</p>
              <p className="info">File Type: {file.type}</p>
              <p className="info">File size: {file.size}</p>
              <p className="info">Last Modified: {" "}
                  {file.lastModifiedDate.toDateString()}
              </p>
              <img style={{ width: 640 }} src={src} alt= {alt} />
          </>
          );
      } else if (uploadStatus === true) {
          return (
              <>
                  <p className="info">Your file has been successfully uploaded</p>
              </>
          );
      } else {
          return (
              <>
                  <p className="info">Choose a file and then press the Upload button</p>
              </>
          )
      }
  } 

//  https://sentry.io/answers/react-for-loops/
// https://blog.openreplay.com/build-an-elegant-gallery-with-react-responsive-carousel
const displayInferenceData = () => {
  if (resultData) {
    return (
      <>
      {/* <Carousel showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}></Carousel> */}
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Carousel showArrows={true} infiniteLoop={true}> 
        {resultData.map((inferencedImage, i) => (
        <div>
          <img src={`data:image/jpeg;base64,${inferencedImage}`}/>
          <p className="legend">{resultList[i]}</p>
        </div>
      )
      )}
      </Carousel>
      </div>
      </>
    )
  }

}

  const handleAddPhoneField = () => {
    setPhoneArray([...phoneArray, { phoneid: uuidv4(), phonenumber: '' }])
    setPhoneMessage('');
  }

  const handleRemovePhoneField = phoneid => {
    const values = [...phoneArray];
    values.splice(values.findIndex(value => value.phoneid === phoneid), 1);
    setPhoneArray(values);
    setPhoneMessage('');
  }  

  const phoneListRetrieval = () => {
    setPhoneLoading(true);
    setTimeout(() => {
        axios({
            method: 'get',
            url: fetchAllUrl,
            headers: {
                'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
                "Content-Type": 'application/json',
            },
            params: {
              username: username,
              fromTable: 'phonelist',
            }
            
          // queryStringParameters: {username: username},
        }).then (response => {
            
            const data = Array.from(response.data.items);
            //console.log(response.data);
            if (data.length === 0) {
              setPhoneArray([{phoneid: uuidv4(), phonenumber: '' }]);
            }
            else {
              setPhoneArray(data);
            }
            setPhoneLoading(false);
            console.log(data);
          }).catch (error => {
            console.log('Error occured', error);
            setPhoneLoading(false);
        })
    }, 2000);
  }


  const handlePhoneSubmit = (event) => {
    event.preventDefault();
    setPhoneMessage('');
    console.log("PhoneArray", phoneArray);
    const requestConfig = {
        headers: {
            'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj'
        }
    }
    const requestBody = {
        phoneArray: phoneArray,
        username: username,
        saveTo: 'phonelist',
    }
    axios.post(crudAssetsUrl, requestBody, requestConfig).then(response => {
        setPhoneMessage('Successfully updated phone numbers for alerting.');
        console.log(response);
        phoneListRetrieval();
    }).catch(error => {
        if (error.response.status === 401 || error.response.status === 403) {
            setPhoneMessage(error.response.data.message);
        } else{
            setPhoneMessage('sorry... the backend server is down!! please try again later');
        }
    })    
  };

  const handlePhoneChange = (phoneid, event) => {
    const newPhoneArray = phoneArray.map(i => {
      if(phoneid === i.phoneid) {
        i[event.target.name] = event.target.value;
      }
      return i;
    })
    setPhoneArray(newPhoneArray);
    setPhoneMessage('');
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.cameraid);
      setSelected(newSelected);
      console.log(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, cameraid) => {
    const selectedIndex = selected.indexOf(cameraid);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, cameraid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    console.log(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (cameraid) => selected.indexOf(cameraid) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


  const assetListRetrieval = () => {
    setTimeout(() => {
    setLoading(true);  
      axios({
          method: 'get',
          url: fetchAllUrl,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
          },
          params: {
            username: username,
            fromTable: 'assetlist',
          }
          
         // queryStringParameters: {username: username},
      }).then (response => {
          
          
        const data = Array.from(response.data.items);

        for (var i=0; i<data.length; i++){
          data[i].accessstring = 'On File';
        }

          //console.log('ACCESS STRINGS: ', response.data.items[0].accessstring);
          console.log('ASSET DATA: ', data);
          setRows(data);
          setLoading(false);
          //console.log(data);
        }).catch (error => {
          console.log('Error occured', error);
          setLoading(false);
      })
    }, 2000);
  }

  const registerAssetsHandler = (event) => {
    event.preventDefault();
    //setAid(uuidv4().toString());
    //setAdateadded(new Date().toLocaleString() + '');
    var newID = uuidv4().toString();
    var newDATE = new Date().toLocaleString() + '';
    setAid(newID);
    setAdateadded(newDATE);
    console.log(aid);
    if  (aid.trim() === '' || aname.trim() === '' || alocation.trim() === '' || adateadded.trim() === '' || aaccessstring.trim() === '') {
        setMessage('Please fill out the required fields.');
        return;
    }

    const requestConfig = {
        headers: {
            'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj'
        }
    }
    const requestBody = {
        cameraid: aid,
        name: aname,
        loc: alocation,
        date: adateadded,
        accessstring: aaccessstring,
        username: username,
        saveTo: 'assetlist',
    }
    axios.post(crudAssetsUrl, requestBody, requestConfig).then(response => {
        setMessage('Successfully added new asset.');
        console.log(response);
        assetListRetrieval();
    }).catch(error => {
        if (error.response.status === 401 || error.response.status === 403) {
            setMessage(error.response.data.message);
        } else{
            setMessage('sorry... the backend server is down!! please try again later');
        }
    })

  }

  const deleteAssetsHandler = (event) => {
    event.preventDefault();
    for (var i=0; i < selected.length; i++) {
      console.log('DELETING ', selected[i])
      const assetDeleteUrl = 'https://fd1k8w830l.execute-api.us-east-1.amazonaws.com/prod/item';
      axios({
          method: 'delete',
          url: assetDeleteUrl,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
          },
          params: {
            username: username,
            cameraid: selected[i],
            deleteFrom: 'assetlist',
          }
      }).then (response => {
        setMessage('Successfully deleted existing asset.');
        console.log(response);
        assetListRetrieval();
      }).catch (error => {
        console.log('Error occured', error);
        setMessage('sorry... the backend server is down!! please try again later');
      })
    }
  }

  const handleTXModeChange = (event) => {
    setTXMode(event.target.value);
    setSettingsMessage(null);
  };
  const handleRunStartTimeChange = (event) => {
    setRunStartTime(event.target.value);
    setSettingsMessage(null);
  };
  const handleRunEndTimeChange = (event) => {
    setRunEndTime(event.target.value);
    setSettingsMessage(null);
  };
  const handleDetectionDaysChange = (event) => {
    setDetectionDays(event.target.value);
    setSettingsMessage(null);
  };
  const handleComputeLocationChange = (event) => {
    setComputeLocation(event.target.value);
    setSettingsMessage(null);
  };
  const handleDetectionFrequencyChange = (event) => {
    setDetectionFrequecy(event.target.value);
    setSettingsMessage(null);
  };

  const configsRetrieval = () => {
    setTimeout(() => {
    setSettingsLoading(true);  
      axios({
          method: 'get',
          url: crudAssetsUrl,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
          },
          params: {
            username: username,
            fromTable: 'deployments',
          }
      }).then (response => {
          console.log("CONFIGURATIONS: ", response.data);
          setTXMode(response.data.txmode);
          setRunStartTime(response.data.starttime);
          setRunEndTime(response.data.endtime);
          setDetectionDays(response.data.detectiondays);
          setDetectionFrequecy(response.data.detectionfrequency);
          setComputeLocation(response.data.computelocation);
          setAppRunning(response.data.running);
          setSettingsLoading(false);
        }).catch (error => {
          console.log('Error occured', error);
          setSettingsLoading(false);
      })
    }, 2000);
  }

  const EC2stateRetrieval = () => {
    const startEC2InstanceURL = 'https://vlrop6ylf8.execute-api.us-east-1.amazonaws.com/prod/servers/readec2status';
    setTimeout(() => {
    setEC2StateLoading(true);
      axios({
          method: 'get',
          url: startEC2InstanceURL,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
          }
      }).then (response => {
          console.log("COMPUTE INSTANCE STATUS: ", response.data.body);
          setComputeState(response.data.body.state)
          setComputeStatus(response.data.body.status);
          setRunningMessage("Detection service " + response.data.body.state + " [status: " + response.data.body.status + "]");
          setEC2StateLoading(false);
      }).catch (error => {
          console.log('Error occured', error);
          setEC2StateLoading(false);
      })   
    }, 2000);
  }  

  const registerConfigsHandler = (event) => {
    event.preventDefault();
    if  (txmode.trim() === '' || runstarttime.trim() === '' || runendtime.trim() === '' || detectiondays === null || detectionfrequency === null || computelocation.trim() === '') {
        setSettingsMessage('Please fill out all the required fields.');
        return;
    }

    const requestConfig = {
      headers: {
          'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj'
      }
    }
    const requestBody = {
      txmode: txmode,
      runstarttime: runstarttime,
      runendtime: runendtime,
      detectiondays: detectiondays,
      detectionfrequency: detectionfrequency,
      computelocation: computelocation,
      username: username,
      running: appRunning,
      saveTo: 'deployments'
    }
    axios.post(crudAssetsUrl, requestBody, requestConfig).then(response => {
        setSettingsMessage('Successfully updated deployment plan.');
        console.log(response);
    }).catch(error => {
        if (error.response.status === 401 || error.response.status === 403) {
            setSettingsMessage(error.response.data.message);
        } else{
            setSettingsMessage('sorry... the backend server is down!! please try again later');
        }
    })
  }

  useEffect(() => {
    setResultData(file);
  }, [file]);
  
  useEffect(() => {
    if (((computeState === 'running') && (computeStatus !== 'ok')) || (computeState ==='pending') || (computeState === 'stopping')) {
      EC2CheckIntervalID.current = setInterval(() => {
        EC2stateRetrieval();
      }, 2000);
    }
    else if ((computeState === 'running') && (computeStatus === 'ok')) {
      setTimeout(() => {
        console.log('Compute instance started, now starting the detection application on the instance...');
      }, 2000);      
      startDetectionApp();
    }
    return () => {
      clearInterval(EC2CheckIntervalID.current);
    }; 
  }, [computeState, computeStatus]);
/*
  useEffect(() => {
    if ((appRunning === 'running') && stopAppTrigger) {
      appRunCheckIntervalID.current = setInterval(() => {
        configsRetrieval();
        console.log('STOP TRIGGER: ', stopAppTrigger, appRunning);
      }, 2000);
    }
    else if ((appRunning === 'stopped') && stopAppTrigger) {
      setTimeout(() => {
        console.log('Detection application stopped on instance, now shutting down the instance...');
      }, 2000);      
      //shutDownEC2();
      setStopAppTrigger(false);
    }
    return () => {
      clearInterval(appRunCheckIntervalID.current);
    };
  }, [stopAppTrigger]);
*/
  const startServiceHandler = () => {
    const startEC2InstanceURL = 'https://vlrop6ylf8.execute-api.us-east-1.amazonaws.com/prod/servers/startec2instance';
    axios({
        method: 'get',
        url: startEC2InstanceURL,
        headers: {
            'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
            "Content-Type": 'application/json',
        }
    }).then (response => {
        console.log(response.data.body);
        EC2stateRetrieval();
    }).catch (error => {
        console.log('Error occured', error);
        setRunningMessage(error.response.data.message);
    })
  }

  const startDetectionApp = () => {
    configsRetrieval();
    setTimeout(() => {
      console.log('Waiting for the detection app run status to update...');
    }, 2000);
    if(appRunning === 'stopped') {
      const startEC2InstanceURL = 'https://vlrop6ylf8.execute-api.us-east-1.amazonaws.com/prod/servers/startdetectionapp?user=' + username;
      axios({
          method: 'get',
          url: startEC2InstanceURL,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
          }
      }).then (response => {
          console.log('START DETECTION APP RESPONSE: ', response.data.body.response);
      }).catch (error => {
          console.log('Error occured', error);
      })      
    }
    else {
      console.log('Detection application is already running on the compute instance.');
    }
  }  

  const stopServiceHandler = () => {
    configsRetrieval();
    setStopAppTrigger(true);
  }
    
  const shutDownEC2 = () => {
    //if ((appRunning === 'stopped') && stopAppTrigger) {
    //  setTimeout(() => {
        console.log('Detection application stopped on instance, now shutting down the instance...');
    //  }, 2000);      
      const stopEC2InstanceURL = 'https://vlrop6ylf8.execute-api.us-east-1.amazonaws.com/prod/servers/stopec2instance';
      axios({
          method: 'get',
          url: stopEC2InstanceURL,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
          }
      }).then (response => {
          console.log(response.data.body);
          EC2stateRetrieval();
      }).catch (error => {
          console.log('Error occured', error);
          setRunningMessage(error.response.data.message);
      })
      setStopAppTrigger(false);
    //}
  }

  useEffect(() => {
    if (stopAppTrigger) {
      stopDetectionApp();
    }
  }, [stopAppTrigger]);

  
  const stopDetectionApp = () => {
    //if((appRunning === 'running') && stopAppTrigger) {
      const startEC2InstanceURL = 'https://vlrop6ylf8.execute-api.us-east-1.amazonaws.com/prod/servers/stopdetectionapp?user=' + username;
      axios({
          method: 'get',
          url: startEC2InstanceURL,
          headers: {
              'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj',
              "Content-Type": 'application/json',
          }
      }).then (response => {
          console.log('STOP DETECTION APP RESPONSE: ', response.data.body.response);
          shutDownEC2();
      }).catch (error => {
          console.log('Error occured', error);
      })
    //}
    //else {
    //  console.log('Detection application has already been stopped on the compute instance.');
    //}
  }

  return (
    <>
    <div className="customer-page">
      <Stack 
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Row>
          <Col>
            <Typography variant="h5" gutterBottom align="center">Hello <b>{name}</b>.</Typography>
            <Typography variant="h6" gutterBottom align="center">Welcome to your asset management portal.</Typography>

            {((computeState === 'running') && (computeStatus === 'ok')) ? (
                <Box textAlign='center'>
                  <Button variant="contained" color="error" startIcon={<StopCircleIcon />} type="submit" sx={{ width: 200 }} onClick={stopServiceHandler}>Stop Service</Button>

              </Box>
              ) : computeState === 'stopped' ? (
                <Box textAlign='center'>
                  <Button variant="contained" startIcon={<PlayCircleFilledIcon />} type="submit" sx={{ width: 200 }} onClick={startServiceHandler}>Start Service</Button>

                </Box>
              ) : (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Please Wait...</div>
              )
            }

            {EC2Stateloading ? (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>...Checking Compute Status...</div>
            ) : (     
              <Box textAlign='center'>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{runningmessage}</div>

              </Box>
            )}


          </Col>
        </Row>
      </Stack>
    </div>
    <br></br>
    <div className="file-card">
        <Typography variant="h6" gutterBottom><b>Register a new asset</b></Typography>
        <Box component="form" sx={{'& > :not(style)': { m: 1, width: '50ch' }, }} noValidate autoComplete="off">
            <TextField id="cameraName" label="Asset Name" variant="standard" onChange={event => setAname(event.target.value)}/>
            <br></br>
            <TextField id="cameraLocation" label="Asset Location" variant="standard" onChange={event => setAlocation(event.target.value)}/>
            <br></br>
            <TextField id="accessString" type='password' label="Access String" variant="standard" onChange={event => setAaccessstring(event.target.value)}/>
        </Box>
        <br></br>
        <Button variant="contained" color="success" startIcon={<VideoCallIcon />} type="submit" sx={{ width: 200 }} onClick={registerAssetsHandler}>Register</Button>
        {message && <p className="message">{message}</p>}
        <br></br>
      
    {loading ? (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>...Data Loading.....</div>
      ) : (

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.cameraid);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.cameraid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.cameraid}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.cameraid}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.loc}</TableCell>
                      <TableCell align="right">{row.date}</TableCell>
                      <TableCell align="right">{row.accessstring}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          //style="display:"flex"; justify-content:"center";
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
      )}

    <div>
      <hr />
      <Typography align="center" variant="body2" color="crimson" gutterBottom>
        <em>
          If you are selecting software access to your assets then please make sure that their IP addresses can be accessed from outside of your network. <br></br>
          Read more about how to set it up <a href=" ">HERE</a>. <br></br><br></br>
          Alternatively, you can use the S5Alert secure gateway module installed in your facility to manage the data transmission from your assets. <br></br>
          You can purchase it from <a href=" ">HERE</a>.
        </em>
      </Typography>
      <hr />
    </div>

    </div>
    <br></br>

    <div className="file-card2">
      <Typography variant="h6" gutterBottom><b>Add phone numbers to receieve alerts</b></Typography>
      {phoneloading ? (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>...Data Loading.....</div>
      ) : (
            <form onSubmit={handlePhoneSubmit}>
            {
              phoneArray.map(phoneInput => {
                return (
                  <div key={phoneInput.phoneid}>
                    <Stack direction="row" spacing={2}>

                      <TextField
                        name="phonenumber" 
                        label="Phone Number" 
                        type="text"
                        variant="outlined" 
                        value={phoneInput.phonenumber} 
                        onChange={event => handlePhoneChange(phoneInput.phoneid, event)}
                        //onChange={event => setPhoneNumber(event.target.value)}

                        margin="normal"/>
                      <div className='centered-div'>
                      <IconButton aria-label="remove" disabled={phoneArray.length === 1} onClick={() => handleRemovePhoneField(phoneInput.phoneid)}> <RemoveIcon /> </IconButton>
                      <IconButton aria-label="add" onClick={handleAddPhoneField}> <AddIcon /> </IconButton>
                      </div>
                    </Stack>
                  </div>
                )
              })
            }
            </form>
      )}
      <div>
        <hr />
        <Typography align="center" variant="body2" color="crimson" gutterBottom>
          <em>
            By providing these cell phone numbers I agree to receive calls and texts to these numbers from S5SAlert that may be automatically dialed or prerecorded. <br></br>
            Message & Data Rates May Apply. Note that if you select to recieve multimedia data then higher cost may be charged by your mobile carrier. <br></br>
            You can remove the phone number to opt-out from receiving messages from S5Alert. We do not sell personal information.
          </em>
        </Typography>
        <hr />
      </div>
      <Button variant="contained" color="secondary" startIcon={<AddIcCallIcon />} type="submit" sx={{ width: 200 }} onClick={handlePhoneSubmit}>Save</Button>
      {phonemessage && <p className="message">{phonemessage}</p>}
    </div>
      
    <br></br>

    <div className="file-card3">
      <Typography variant="h6" gutterBottom><b>Configure surveillance settings</b></Typography>
      <br></br>
      {settingsloading ? (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>...Data Loading.....</div>
      ) : (

      <FormControl className="form-inline">
        <FormLabel id="txmode-row-radio-buttons-group-label">Asset Data transmission Mode</FormLabel>
          <RadioGroup
            row
            aria-labelledby="txmode-row-radio-buttons-group-label"
            name="txmode-row-radio-buttons-group"
            value={txmode}
            onChange={handleTXModeChange}
          >
          <FormControlLabel value="Software" control={<Radio />} label="Software" />
          <FormControlLabel value="Hardware" control={<Radio />} label="Hardware" />
          </RadioGroup>
          <br></br>
          <TextField label='Daily Start Time (hr:min, example: 06:00)' type='text' sx={{ width: 350 }} value={runstarttime} onChange={handleRunStartTimeChange} /> 
          <br></br>
          <TextField label='Daily End Time (hr:min, example: 18:00)' type='text' sx={{ width: 350 }} value={runendtime} onChange={handleRunEndTimeChange}/>
          <br></br>
          <TextField
            style={{ width: "100%" }}
            variant="outlined"
            select
            value={detectiondays}
            label="Detection Days"
            onChange={handleDetectionDaysChange}
          >
            <MenuItem value={5}>Work Days (Monday-Friday)</MenuItem>
            <MenuItem value={7}>All Week</MenuItem>
          </TextField>
          <br></br>          
          <TextField
            style={{ width: "100%" }}
            variant="outlined"
            select
            value={detectionfrequency}
            label="Detection Frequency"
            onChange={handleDetectionFrequencyChange}
          >
            <MenuItem value={3}>Every 3 seconds</MenuItem>
            <MenuItem value={5}>Every 5 seconds</MenuItem>
            <MenuItem value={10}>Every 10 seconds</MenuItem>
            <MenuItem value={15}>Every 15 seconds</MenuItem>
            <MenuItem value={30}>Every 30 seconds</MenuItem>
            <MenuItem value={60}>Every 60 seconds</MenuItem>
          </TextField>
          <br></br>
          <FormLabel id="computemode-row-radio-buttons-group-label">Detection Resource Location</FormLabel>
          <RadioGroup
            row
            aria-labelledby="computemode-row-radio-buttons-group-label"
            name="computemode-row-radio-buttons-group"
            value={computelocation}
            onChange={handleComputeLocationChange}
          >
          <FormControlLabel value="Cloud" control={<Radio />} label="Cloud" />
          <FormControlLabel value="On-premise" control={<Radio />} label="On-premise" />
          </RadioGroup>
          <br></br>
          <Button 
            variant="contained" 
            style={{
              backgroundColor: "#557a95",
              
              //fontSize: "12px",
              //margin: "4px",
              //alignItems: "center"
            }}
            color="secondary" 
            startIcon={<VideoSettingsIcon />} 
            type="submit" 
            sx={{ width: 200 }} 
            onClick = {registerConfigsHandler}
          >
          Save
          </Button>
        {settingsmessage && <p className="message">{settingsmessage}</p>}
      </FormControl>

      )}
      <br></br>
    </div>
    <br></br>
    <div className="file-card4">
      <Typography variant="h6" gutterBottom><b>Review Inferenced Data</b></Typography>
{/*         <div className="file-inputs">
            <input type="file" onChange={onFileChange}/>
            <button>
                <i>
                    <FontAwesomeIcon icon={faImage} />
                    
                </i>
                Select Data
            </button>
        </div>
        <p className="main">Supported files:</p>
        <p className="info">JPG only</p>
        {fileData()}
        <br></br>
        <Button variant="contained" endIcon={<SendIcon />} type="submit" sx={{ width: 200 }} onClick={onFileUpload}>Upload</Button>
        <br></br>
        {errorMessage && <p className="errorMessage">{errorMessage}</p>} */}
        <Button variant="contained" endIcon={<GetAppIcon />} type="submit" sx={{ width: 200 }} onClick={resultRetrieval}>Get Result</Button>
        
      <hr />
      <div>
        <br></br>
        {displayInferenceData()}
        {/* <img src={`data:image/jpg;base64,${resultData}`} alt=""/> */}
      </div>
    </div>
    <br></br>
    <hr />
    <div className="center">
        <Button variant="outlined" startIcon={<LogoutIcon />} type="submit" sx={{ width: 200 }} onClick={logoutHandler}><b>Logout</b></Button>
    </div>
 </>
  )

}

export default CustomerPortal;