import React, { useState } from "react";
import CustomerForm from "./Customer-comp";
//import { ResetPassword } from "../../components/password-reset/PasswordReset.comp";

import "./styles/Customer.scss";

const Customer = () => {
	const [frmLoad, setFrmLoad] = useState("customer");

    //	const handleOnResetSubmit = e => {
//		e.preventDefault();
//	};

	const formSwitcher = frmType => {
		setFrmLoad(frmType);
	};

	return (
		<div className="customer-page bg-primary">
			<div className="jumbotron form-box">
            {/* <Jumbotron className="form-box"> */}
				{frmLoad === "customer" && <CustomerForm formSwitcher={formSwitcher} />}

{/* 				{frmLoad === "rest" && (
					<ResetPassword
						// handleOnChange={handleOnChange}
						handleOnResetSubmit={handleOnResetSubmit}
						formSwitcher={formSwitcher}
						// email={email}
					/>
				)} */}
			</div>
		</div>
	);
};

export default Customer;