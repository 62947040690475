import React, { useState } from "react";
import axios from 'axios';
import { setUserSession } from "./service/AuthService";
import { Row, Col } from "react-bootstrap";
import { Stack, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import PasswordField from "material-ui-password-field";
import "./styles/Login.css";

const loginUrl = 'https://i8a176j6kc.execute-api.us-east-1.amazonaws.com/prod/login';

const LoginForm = (props) => {
   
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    let history = useNavigate();

    const submitHandler = (event) => {
        event.preventDefault();
        if  (username.trim() === '' || password.trim() === '') {
            setErrorMessage('Both username and password are required');
            return;
        }
        setErrorMessage(null);

        const requestConfig = {
            headers: {
                'x-api-key': 'vf2dflpQfQ9DM1qhL678W9MYN3rrJe9E95hI3PQj'
            }
        }
        const requestBody = {
            username: username,
            password: password
        }
        axios.post(loginUrl, requestBody, requestConfig).then(response => {
            setUserSession(response.data.user, response.data.token);
            history('/customer');
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                setErrorMessage(error.response.data.message);
            } else{
                setErrorMessage('sorry... the backend server is down!! please try again later');
            }
        })
    }

    return (
/*        <div class="container">
            <div class="jumbotron">
                <h1>Hello There...</h1>
                <form onSubmit={submitHandler}>
                    <h5>Login</h5>
                    username: <input type="text" value={username} onChange={event => setUsername(event.target.value)} /> <br/>
                    password: <input type="password" value={password} onChange={event => setPassword(event.target.value)} /> <br/>
                    <input type="submit" value="Login" />
                </form>
                {errorMessage && <p className="errorMessage">{errorMessage}</p>}
            </div>
        </div>
*/
        <Stack spacing={2}>
            <Row>
			    <Col>
                    <h5 className="text-info text-center">Customer Login</h5>
					<hr />
                    <form onSubmit={submitHandler}>
                        <Stack direction='column' spacing={2}>

                            <TextField label='username' type='text' onChange={event => setUsername(event.target.value)}/>
                            <TextField label='password' type='password' onChange={event => setPassword(event.target.value)}/>
                            <Button variant="contained" type="submit">Login</Button>
                        
                        </Stack>
                    </form>
                    <hr />
                    {errorMessage && <p className="errorMessage">{errorMessage}</p>}
                </Col>
			</Row>
{/*             <Row>
				<Col>
                <PasswordField hintText="At least 8 characters" floatingLabelText="Enter your password" errorText="Your password is too short" />
				</Col>
			</Row>  */}           
            <Row>
				<Col>
                    <Link to="/">Forgot Password?</Link>
				</Col>
			</Row>
			<Row className="py-4">
				<Col>
					Are you new here? <Link to="/Register">Register Now</Link>
				</Col>
			</Row>
        </Stack>
    );
}

export default LoginForm;